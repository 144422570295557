
header{
    position: fixed;
    top: 0;
    height: 70px;
    background-color: rgb(129, 12, 12);
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    box-shadow: 1px 1px 1px 1px rgba(116, 110, 110, 0.199);
    align-items: start;
}
.header-inner{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

}
.logo{
    font-size: 1.3rem;
    cursor: pointer;
    color: #ece9ea;
    margin-left: 30px;
    
}
.logo:hover{
    color: whitesmoke;
}

nav{
    flex: 0.5;
    display: flex;
    justify-content: space-between;
    text-align: start;
}


.hamburger-btn{
    font-size: 1.3rem;
    position: absolute;
    bottom: 0;
    width: 40px;
    height: 35px;
    right: 30px;
    outline: none;
    background-color: rgba(4,52,111,0.8);
    color: whitesmoke;
    margin-bottom: 17px;
    text-decoration: none;
}
.addTransiton{
    transform: translateX(170px);
    transition: transform 0.5s ease-in-out;
}
.removeTransition{
    transform: translateX(20px);
    transition: transform 0.5s ease-in-out;
}

#sidebar-list{
    background-color:rgba(4,52,111,0.8);
    height: 90vh;
    width: 170px;
    position: absolute;
    z-index: 2000;
    right: 0;
    top: 0;
    margin-top: 70px;
    text-align: start;
    padding-left: 10px;
    text-decoration: none;
}
.nav-big{
    list-style: none;
    display: flex;
    justify-content: space-around;
    width: 70%;
    font-weight: bold;
}

.nav-big-link{
   cursor: pointer;
   color: white;
   text-decoration: none !important;
}

.nav-small{
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-around;
    margin: auto;
    height: 40%;
    margin-top: 50px;
    width: 80%;
}
.nav-small-link{
    cursor: pointer;
    color: whitesmoke;
    padding-bottom: 5px;
}

