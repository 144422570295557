/**
* Template Name: Selecao
* Template URL: https://bootstrapmade.com/selecao-bootstrap-template/
* Updated: Mar 17 2024 with Bootstrap v5.3.3
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
p {
  font-family: Arial;
  font-size: 17px;
}

a {
  color: #47b2e4;
  text-decoration: none;
}

a:hover {
  color: #47b2e4;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Arial;
  font-weight: bold;
}
body{
  font-family: Arial;
}


/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #47b2e4;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 28px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #47b2e4;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Disable AOS delay on mobile
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  height: 70px;
  transition: all 0.5s;
  z-index: 997;
  transition: all 0.5s;
  background: rgba(4, 52, 111, 0.8);
}

#header.header-transparent {
  background: transparent;
}

#header.header-scrolled {
  background: rgba(42, 44, 57, 0.9);
}


#header .logo h1 {
  font-size: 28px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 1px;
}

#header .logo h1 a,
#header .logo h1 a:hover {
  color: #fff;
  text-decoration: none;
}

#header .logo img {
  padding: 0;
  margin: 0;
  max-height: 40px;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 15px 7px 15px;
  margin-left: 5px;
  font-size: 14px;
  color: #fff;
  white-space: nowrap;
  transition: 0.3s;
  border-radius: 50px;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  background: transparent;
  color: #47b2e4;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 5px;
  top: calc(100% + 30px);
  margin: 5px 0 0 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: rgba(4, 52, 111, 0.8);
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 15px;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  color: #2a2c39;
  margin: 0 5px;
  font-size: 14px;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  color: #fff;
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #fff;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s; 
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(20, 21, 28, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  border-radius: 10px;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
  
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  margin: 5px;
  font-size: 15px;
  color: #2a2c39;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
  color: #fff;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  text-decoration: none;
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
  text-decoration: none;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
  text-decoration: none;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
  text-decoration: none;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
  color: #fff;
}

.navbar-mobile .dropdown>.dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  padding: 0;
}

#hero .carousel-container {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  text-align: center;
  position: relative;
  height: 70vh;
  padding-top: 60px;
}

#hero h2 {
  color: #fff;
  margin-bottom: 30px;
  font-size: 48px;
  font-weight: 700;
}

#hero p {
  width: 80%;
  animation-delay: 0.4s;
  margin: 0 auto 30px auto;
  color: #fff;
}

#hero .carousel-control-prev,
#hero .carousel-control-next {
  width: 10%;
}

#hero .carousel-control-next-icon,
#hero .carousel-control-prev-icon {
  background: none;
  font-size: 48px;
  line-height: 1;
  width: auto;
  height: auto;
}

#hero .btn-get-started {
  font-family: Arial;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 0px;
  transition: 0.5s;
  line-height: 1;
  margin: 10px;
  color: #fff;
  animation-delay: 0.8s;
  border: 2px solid #47b2e4;
  text-decoration: none;
}

#hero .btn-get-started:hover {
  background: #47b2e4;
  color: #fff;
  text-decoration: none;
}

@media (min-width: 1024px) {
  #hero p {
    width: 60%;
  }

  #hero .carousel-control-prev,
  #hero .carousel-control-next {
    width: 5%;
  }
}

@media (max-width: 100px) {
  #hero .carousel-container {
    height: 90vh;
  }

  #hero h2 {
    font-size: 28px;
  }
}

.hero-waves {
  display: block;
  width: 100%;
  height: 60px;
  position: relative;
}

.wave1 use {
  animation: move-forever1 10s linear infinite;
  animation-delay: -2s;
}

.wave2 use {
  animation: move-forever2 8s linear infinite;
  animation-delay: -2s;
}

.wave3 use {
  animation: move-forever3 6s linear infinite;
  animation-delay: -2s;
}

@keyframes move-forever1 {
  0% {
    transform: translate(85px, 0%);
  }

  100% {
    transform: translate(-90px, 0%);
  }
}

@keyframes move-forever2 {
  0% {
    transform: translate(-90px, 0%);
  }

  100% {
    transform: translate(85px, 0%);
  }
}

@keyframes move-forever3 {
  0% {
    transform: translate(-90px, 0%);
  }

  100% {
    transform: translate(85px, 0%);
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-title {
  padding-bottom: 40px;
}

.section-title h2 {
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  line-height: 1px;
  margin: 0 0 5px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #aaaaaa;
  font-family: Arial;
}

.section-title h2::after {
  content: "";
  width: 120px;
  height: 1px;
  display: inline-block;
  background: #47b2e4;
  margin: 4px 10px;
}

.section-title p {
  margin: 0;
  margin: 0;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: Arial;
  color: #2a2c39;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about {
  padding-top: 80px;
}

.about .content h3 {
  font-weight: 600;
  font-size: 26px;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding-left: 28px;
  position: relative;
}

.about .content ul li+li {
  margin-top: 10px;
}

.about .content ul i {
  position: absolute;
  left: 0;
  top: 2px;
  font-size: 20px;
  color: #47b2e4;
  line-height: 1;
}

.about .content p:last-child {
  margin-bottom: 0;
}

.about .content .btn-learn-more {

  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 5px;
  transition: 0.3s;
  line-height: 1;
  color: #47b2e4;
  animation-delay: 0.8s;
  margin-top: 6px;
  border: 2px solid #47b2e4;
}

.about .content .btn-learn-more:hover {
  background: #47b2e4;
  color: #fff;
  text-decoration: none;
}

/*--------------------------------------------------------------
# Features
--------------------------------------------------------------*/
.features .nav-tabs {
  border: 0;
}

.features .nav-link {
  border: 1px solid #d4d6df;
  padding: 15px;
  transition: 0.3s;
  color: #2a2c39;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.features .nav-link i {
  padding-right: 15px;
  font-size: 48px;
}

.features .nav-link h4 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.features .nav-link:hover {
  color: #47b2e4;
}

.features .nav-link.active {
  background: #47b2e4;
  color: #fff;
  border-color: #47b2e4;
}

@media (max-width: 768px) {
  .features .nav-link i {
    padding: 0;
    line-height: 1;
    font-size: 36px;
  }
}

@media (max-width: 575px) {
  .features .nav-link {
    padding: 15px;
  }

  .features .nav-link i {
    font-size: 24px;
  }
}

.features .tab-content {
  margin-top: 30px;
}

.features .tab-pane h3 {
  font-weight: 600;
  font-size: 26px;
}

.features .tab-pane ul {
  list-style: none;
  padding: 0;
}

.features .tab-pane ul li {
  padding-bottom: 10px;
}

.features .tab-pane ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #47b2e4;
}

.features .tab-pane p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Cta
--------------------------------------------------------------*/
.cta {
  background: #2a2c39;
  padding: 80px 0;
}

.cta h3 {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
}

.cta p {
  color: #fff;
}

.cta .cta-btn {
  font-family: Arial;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 30px;
  border-radius: 50px;
  transition: 0.5s;
  margin: 10px;
  color: #fff;
  background: #47b2e4;
}

.cta .cta-btn:hover {
  background: #fff;
  color: #47b2e4;
}

@media (max-width: 1024px) {
  .cta {
    background-attachment: scroll;
  }
}

@media (min-width: 769px) {
  .cta .cta-btn-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
  padding: 30px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 2px 29px 0 rgba(68, 88, 144, 0.12);
  transition: all 0.4s ease-in-out;
  width: 100%;
  height: 100%;

}

.services .icon-box:hover {
  transform: translateY(-10px);
  box-shadow: 0 2px 35px 0 #47b2e4;
}

.services .icon {
  position: absolute;
  left: 6px;
  margin-left: 10px;
  top: calc(50% - 30px);
  color: #47b2e4;
}

.services .icon i {
  font-size: 64px;
  line-height: 1;
  transition: 0.5s;

}

.services .title {
  margin-left: 80px;
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

.services .title a {
  color: #2a2c39;
  transition: ease-in-out 0.3s;
}

.services .title a:hover {
  color: #47b2e4;
}

.services .description {
  font-size: 16px;
  margin-left: 80px;
  line-height: 24px;
  margin-bottom: 0;
  color: rgb(13, 14, 15);
  text-align: justify !important;
  hyphens: auto;
  font-family: Arial;
}

/*--------------------------------------------------
# F.A.Q
--------------------------------------------------------------*/
.faq {
  padding: 60px 0;
}

.faq .faq-list {
  padding: 0;
  list-style: none;
}

.faq .faq-list li {
  border-bottom: 0px solid #ececf1;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.faq .faq-list .question {
  display: block;
  position: relative;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  padding-left: 25px;
  cursor: pointer;
  color: #47b2e4;
  transition: 0.3s;
}

.faq .faq-list i {
  font-size: 16px;
  position: absolute;
  left: 0;
  top: -2px;
}

.faq .faq-list p {
  margin-bottom: 0;
  padding: 10px 0 0 25px;
}

.faq .faq-list .icon-show {
  display: none;
}

.faq .faq-list .collapsed {
  color: black;
}

.faq .faq-list .collapsed:hover {
  color: #47b2e4;
}

.faq .faq-list .collapsed .icon-show {
  display: inline-block;
  transition: 0.6s;
}

.faq .faq-list .collapsed .icon-close {
  display: none;
  transition: 0.6s;
}

/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team {
  background: #fff;
  padding: 60px 0;
}

.team .member {
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.team .member .member-img {
  position: relative;
  overflow: hidden;
}

.team .member .social {
  position: absolute;
  left: 0;
  bottom: 30px;
  right: 0;
  opacity: 0;
  transition: ease-in-out 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.team .member .social a {
  transition: color 0.3s;
  margin: 0 3px;
  border-radius: 50px;
  width: 36px;
  height: 36px;
  background: rgba(4, 52, 111);
  transition: ease-in-out 0.3s;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.team .member .social a:hover {
  background: ragba(4, 52, 111);
}

.team .member .social i {
  font-size: 18px;
  line-height: 0;
}

.team .member .member-info {
  padding: 25px 15px;
}

.team .member .member-info h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 18px;
  color: #2a2c39;
}

.team .member .member-info span {
  display: block;
  font-size: 13px;
  font-weight: 400;
  color: #aaaaaa;
}

.team .member .member-info p {
  font-family: Arial;
  font-size: 14px;
  line-height: 26px;
  color: #777777;
}

.team .member:hover .social {
  opacity: 1;
  bottom: 15px;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info {
  width: 100%;
  background: #fff;
}

.contact .info i {
  font-size: 20px;
  color: #101315;
  float: left;
  width: 44px;
  height: 44px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #2a2c39;
}

.contact .info p {
  padding: 0 0 0 60px;
  margin-bottom: 0;
  font-size: 14px;
  color: #555974;
}

.contact .info .email,
.contact .info .phone {
  margin-top: 40px;
}

.contact .info .email:hover i,
.contact .info .address:hover i,
.contact .info .phone:hover i {
  background: #47b2e4;
  color: #fff;
  font-family: Arial;
}

.contact .php-email-form {
  width: 100%;
  background: #fff;
}

.contact .php-email-form .form-group {
  padding-bottom: 8px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #47b2e4;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br+br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

.contact .php-email-form input {
  height: 44px;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}

.contact .php-email-form button[type=submit] {
  background: #47b2e4;
  border: 0;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
  border-radius: 50px;
}

.contact .php-email-form button[type=submit]:hover {
  background: #47b2e4;
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 15px 0;
  margin-top: 70px;
  background: #f7f8f9;
  min-height: 40px;
}

.breadcrumbs h2 {
  font-size: 28px;
  font-weight: 400;
  margin: 0;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

@media (max-width: 992px) {
  .breadcrumbs ol {
    margin-top: 10px;
  }
}

.breadcrumbs ol li+li {
  padding-left: 10px;
}

.breadcrumbs ol li+li::before {
  display: inline-block;
  padding-right: 10px;
  color: #404356;
  content: "/";
}

@media (max-width: 768px) {
  .breadcrumbs .d-flex {
    display: block !important;
  }

  .breadcrumbs ol {
    display: block;
  }

  .breadcrumbs ol li {
    display: inline-block;
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/

.carousel-container {
  animation: fadeInDown 1s ease forwards;
  /* Apply fadeInDown animation */
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-50px);
    /* Start 50px above its final position */
  }

  100% {
    opacity: 1;
    transform: translateY(0);
    /* Move down to its final position */
  }
}



.containe {
  /* margin: 5vh 2.5vw; */
  /* padding: 15vh 0; */
  background: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-left: 200px;
}

@media (max-width:992px) {
  .containe {
    margin-left: 0px;
  }
}

.containe svg {
  height: 5rem;
}

.containe svg line {
  stroke: #5f39dd;
  stroke-width: 3px;
  stroke-linecap: round;
  stroke-dasharray: 2px 20px;
  animation: animateline 5s linear both infinite;
}

.containe__sources:before,
.containe__build:before,
.containe__deploy:before {
  position: absolute;
  right: 0;
  bottom: 100%;
  color: #fff;
  background: #ffb238;
  text-transform: uppercase;
  font-size: 0.9rem;
  padding: 0.25rem 0.75rem;
  border-radius: 2.5px;
}

.card,
.containe__sources div,
.containe__build div {
  line-height: 2;
  background: #fff;
  min-width: 200px;
  align-items: center;
  padding: 1.2rem 1rem;
  border-radius: 4px;
  box-shadow: 0 2px 10px #e6e6e6;
}

.containe__sources div i,
.containe__build div i {


  align-items: center;
}

.containe__sources .sources i {
  /* background-color: #02246a; */
  /* max-height: 143px;
    max-width: 143px; */
  border-radius: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: auto;
}

.containe__sources .sources h3 {
  color: #000000;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  align-items: center;
  text-align: center;
  margin-top: 20px !important;
  transition: ease-in all 0.5s;
  margin: auto;
}

#containe__hover:hover.sources {
  cursor: pointer;
  box-shadow: 0px 0px 30px rgba(57, 56, 56, 0.2);
  background-color: #fff;
  border-top: #47b2e4 solid 6px;
  transition: ease-in all 0.5s;
}

#containe_hover:hover.sources h3 {
  color: #47b2e4;
  transition: ease-in all 0.5s;
}

.containe {
  /* margin: 5vh 2.5vw; */
  /* padding: 15vh 0; */
  background: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.containe svg {
  height: 5rem;
}

.containe svg line {
  stroke: #5f39dd;
  stroke-width: 3px;
  stroke-linecap: round;
  stroke-dasharray: 2px 20px;
  animation: animateline 5s linear both infinite;
}

.containe__sources .build h3 {
  color: #37517e;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  align-items: center;
  text-align: center;
  margin-top: 20px !important;
  transition: ease-in all 0.5s;
  margin: auto;
}

.containe__sources {
  display: flex;
  border-radius: 8px;
  min-width: 200px;
  min-height: fit-content;
  padding: 10px;
  background: #f9f9f9;
  position: relative;
}

.containe__sources div {
  text-align: left;
  margin: 0 1rem;
}

.containe__build {
  padding: 5vh 5vw;
  border-radius: 8px;

  background: #f9f9f9;
  position: relative;
}

.containe__build .build div {
  margin: 2rem 0;
  min-width: 150px;
  min-height: 150px;
}

.containe__build div svg {
  width: 4rem;
  height: auto;
  fill: #5f39dd;
}

.containe__deploy {
  background: #f9f9f9;
  padding: 1.5rem;
  border-radius: 8px;
  position: relative;
}

@media (max-width: 700px) {
  .containe__sources {
    flex-direction: column;
  }

  .containe__sources div {
    margin: 1rem 0;
  }
}

@-moz-keyframes animateline {
  from {
    stroke-dashoffset: 0;
  }

  to {
    stroke-dashoffset: -5rem;
  }
}

@-webkit-keyframes animateline {
  from {
    stroke-dashoffset: 0;
  }

  to {
    stroke-dashoffset: -5rem;
  }
}

@-o-keyframes animateline {
  from {
    stroke-dashoffset: 0;
  }

  to {
    stroke-dashoffset: -5rem;
  }
}

@keyframes animateline {
  from {
    stroke-dashoffset: 0;
  }

  to {
    stroke-dashoffset: -5rem;
  }
}

#footer {
  background: rgb(4, 52, 111, 0.8);
  padding: 0 0 30px 0;
  color: #fff;
  font-size: 14px;
}

#footer .footer-newsletter {
  padding: 50px 0;
  background: #0d2735;
}

#footer .footer-newsletter h4 {
  font-size: 24px;
  margin: 0 0 20px 0;
  padding: 0;
  line-height: 1;
  font-weight: 600;
  color: #040404;
}

#footer .footer-newsletter form {
  margin-top: 30px;
  background: #070707;
  padding: 6px 10px;
  position: relative;
  border-radius: 50px;
}

#footer .footer-newsletter form input[type=email] {
  border: 0;
  padding: 4px;
  width: calc(100% - 100px);
}

#footer .footer-newsletter form input[type=submit] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  margin: 3px;
  background: #68A4C4;
  color: #0c0b0b;
  transition: 0.3s;
  border-radius: 50px;
}

#footer .footer-newsletter form input[type=submit]:hover {
  background: #468db3;
}

#footer .footer-top {
  background: #fff;
  padding: 60px 0 30px 0;
}

#footer .footer-top .footer-info {
  margin-bottom: 30px;
}

#footer .footer-top .footer-info h3 {
  font-size: 18px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  color: black;
  font-weight: 600;
}

#footer .footer-top .footer-info p{
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: Arial;
  color: #090606;
}

#footer .footer-top .footer-info span{
  font-size: 13px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: Arial;
  color: #090606;
}

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #1e4356;
  color: white;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  background: #47b2e4;
  color: #fff;
  text-decoration: none;
}

#footer .footer-top h4 {
  font-size: 20px;
  font-weight: 600;
  color: #070707;
  position: relative;
  padding-bottom: 12px;
  
}



#footer .footer-top .footer-links {
  margin-bottom: 30px;
}
/* Add this CSS to your stylesheet */
.footer-links a {
  text-decoration: none; /* Remove underlines from links */
  color: inherit; /* Inherit text color */
}


#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: black;
  font-size: 18px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #181616;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
  color: #7baadb;
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact p {
  line-height: 26px;
}

#footer .copyright {
  text-align: center;
  padding-top: 30px;
}
#footer .copyright a{
  color: #47b2e4;
}

#footer .credits {
  padding-top: 10px;
  text-align: center;
  font-size: 13px;
  color: #0a0808;
}

#footer .credits a {
  color: #050607;
}



.circle-container {
  position: relative;
  width: 500px;
  height: 500px;
  margin: 40px auto;
  border-radius: 50%;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle {
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #fff;
  border: 3px solid rgba(4, 28, 57, 0.8);
  text-align: center;
  padding: 20px;
  font-size: 14px;

}

#center-circle {
  z-index: 1;
  background-color: #f8f7f7;
  color: #0c0101;
  font-size: 105px;
  border: 10px solid rgba(4, 28, 57, 0.8);
  width: 200px;
  height: 200px;
}

.circle:not(#center-circle) {
  transform: translate(150px);
  width: 120px;
  height: 120px;
}

#circle-1 {
  transform: translate(-100%, -280%);
  top: 55%;
  left: 56%;
  font-size: 45px;
}

#circle-2 {
  transform: translate(-100%, -100%);
  top: 24%;
  left: 55%;
  font-size: 45px;
}

#circle-3 {
  transform: translate(-100%, -100%);
  top: -1%;
  left: 40%;
  font-size: 45px;
}

#circle-4 {
  transform: translate(-100%, -100%);
  top: -10%;
  left: 10%;
  font-size: 45px;
}

#circle-5 {
  transform: translate(-100%, -100%);
  top: 1%;
  left: -22%;
  font-size: 45px;
}

#circle-6 {
  transform: translate(-100%, -100%);
  top: 25%;
  left: -38%;
  font-size: 45px;
}

#circle-7 {
  transform: translate(-100%, -100%);
  top: 57%;
  left: -38%;
  font-size: 45px;
}

#circle-8 {
  transform: rotate(360deg) translate(150px);
}

/* Add lines connecting circles to the center circle */
.circle:not(#center-circle):before {
  position: absolute;
  background-color: #f0ebeb;
  color: black;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}




/* gchgv */

.circle-container1 {
  position: relative;
  width: 250px;
  height: 250px;
  margin: 40px auto;
  border-radius: 50%;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle1 {
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #fff;
  border: 3px solid rgba(4, 28, 57, 0.8);
  text-align: center;
  padding: 20px;
  font-size: 14px;

}

#center-circle1 {
  z-index: 1;
  background-color: #f8f7f7;
  color: #0c0101;
  font-size: 105px;
  border: 10px solid rgba(4, 28, 57, 0.8);
  width: 100px;
  height: 100px;
}

.circle1:not(#center-circle1) {
  transform: translate(150px);
  width: 60px;
  height: 60px;
}

#circle-11{
  transform: translate(-100%, -280%);
  top: 55%;
  left: 25%;
  font-size: 45px;
}

#circle-21 {
  transform: translate(-100%, -100%);
  top: 24%;
  left: 25%;
  font-size: 45px;
}

#circle-31 {
  transform: translate(-100%, -100%);
  top: 0%;
  left: 8%;
  font-size: 45px;
}

#circle-41 {
  transform: translate(-100%, -100%);
  top: -10%;
  left: -19%;
  font-size: 45px;
}

#circle-51 {
  transform: translate(-100%, -100%);
  top: 0%;
  left: -47%;
  font-size: 45px;
}

#circle-61 {
  transform: translate(-100%, -100%);
  top:24%;
  left: -68%;
  font-size: 45px;
}

#circle-71 {
  transform: translate(-100%, -100%);
  top: 55%;
  left: -70%;
  font-size: 45px;
}

#circle-81 {
  transform: rotate(360deg) translate(150px);
}

/* Add lines connecting circles to the center circle */
.circle1:not(#center-circle1):before {
  position: absolute;
  background-color: #f0ebeb;
  color: black;
  transform: translate(-50%, -50%);
  top: 25%;
  left: 25%;
}



.header-dark {
  background: url('https://cdn.pixabay.com/photo/2015/10/12/14/59/milky-way-984050_960_720.jpg') #444;
  background-size: cover;
  padding-bottom: 80px;
}

@media (min-width:768px) {
  .header-dark {
    padding-bottom: 120px;
  }
}

.header-dark .navbar {
  background: transparent;
  padding-top: 0;
  padding-bottom: 0;
  color: #fff;
  border-radius: 0;
  box-shadow: none;
  border: none;
}

@media (min-width:768px) {
  .header-dark .navbar {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }
}

.header-dark .navbar .navbar-brand {
  font-weight: bold;
  color: inherit;
}

.header-dark .navbar .navbar-brand:hover {
  color: #f0f0f0;
}

.header-dark .navbar .navbar-collapse span {
  margin-top: 5px;
}

.header-dark .navbar .navbar-collapse span .login {
  color: #d9d9d9;
  margin-right: .5rem;
  text-decoration: none;
}

.header-dark .navbar .navbar-collapse span .login:hover {
  color: #fff;
}

.header-dark .navbar .navbar-toggler {
  border-color: #747474;
}

.header-dark .navbar .navbar-toggler:hover,
.header-dark .navbar-toggler:focus {
  background: none;
}

.header-dark .navbar .navbar-toggler {
  color: #eee;
}

.header-dark .navbar .navbar-collapse,
.header-dark .navbar .form-inline {
  border-color: #636363;
}

@media (min-width: 992px) {
  .header-dark .navbar.navbar .navbar-nav .nav-link {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }
}

.header-dark .navbar.navbar-dark .navbar-nav .nav-link {
  color: #d9d9d9;
}

.header-dark .navbar.navbar-dark .navbar-nav .nav-link:focus,
.header-dark .navbar.navbar-dark .navbar-nav .nav-link:hover {
  color: #fcfeff !important;
  background-color: transparent;
}

.header-dark .navbar .navbar-nav>li>.dropdown-menu {
  margin-top: -5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, .1);
  background-color: #fff;
  border-radius: 2px;
}

.header-dark .navbar .dropdown-menu .dropdown-item:focus,
.header-dark .navbar .dropdown-menu .dropdown-item {
  line-height: 2;
  font-size: 14px;
  color: #37434d;
}

.header-dark .navbar .dropdown-menu .dropdown-item:focus,
.header-dark .navbar .dropdown-menu .drodown-item:hover {
  background: #ebeff1;
}

.header-dark .navbar .action-button,
.header-dark .navbar .action-button:active {
  background: #208f8f;
  border-radius: 20px;
  font-size: inherit;
  color: #fff;
  box-shadow: none;
  border: none;
  text-shadow: none;
  padding: .5rem .8rem;
  transition: background-color 0.25s;
}

.header-dark .navbar .action-button:hover {
  background: #269d9d;
}

.header-dark .navbar .form-inline label {
  color: #ccc;
}

.header-dark .navbar .form-inline .search-field {
  display: inline-block;
  width: 80%;
  background: none;
  border: none;
  border-bottom: 1px solid transparent;
  border-radius: 0;
  color: #ccc;
  box-shadow: none;
  color: inherit;
  transition: border-bottom-color 0.3s;
}

.header-dark .navbar .form-inline .search-field:focus {
  border-bottom: 1px solid #ccc;
}

.header-dark .hero {
  margin-top: 60px;
}

@media (min-width:768px) {
  .header-dark .hero {
    margin-top: 20px;
  }
}

.header-dark .hero h1 {
  color: #fff;
  font-family: Arial;
  font-size: 40px;
  margin-top: 20px;
  margin-bottom: 80px;
}

.qmsarchitech {
  height: '20vh'
}

@media (min-width:768px) {
  .header-dark .hero h1 {
    margin-bottom: 50px;
    line-height: 1.5;
  }

  .qmsarchitech {
    height: '30vh'
  }
}

.header-dark .hero .embed-responsive iframe {
  background-color: #666;
}

.scrollTop {
  cursor: pointer;
}

.scrollTop:focus {
  outline: none;
}

.scrollTop:active {
  scroll-behavior: smooth;

  scroll-margin-top: 0;
}

#downloads .download-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
  color: #000;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: rgb(211, 231, 243);
  margin-left: 20rem;
  margin-right: 20rem;
  margin-top: 4px;
  height: 3rem;
  border-radius: 3px;
}

.download-icon {
  width: 24px;
  height: auto;
}

.downloads-icon {
  margin-right: 100px;
}


.qms {
  margin-left: 100px;
}


#ravindranathimg {
  width: '10px' !important;
  height: '10px';

  margin-bottom: '100px';

}

.elnimg {
  height: 45vh;
  margin-top: 10px;
}
.lablims{
  height: 45vh;
  margin-top: 10px;
}
.adbsimg{
  height: 45vh;
}

@media screen and (max-width: 768px) {
  #ravindranathimg {
    width: '10px' !important;
    height: '10px';
    margin-bottom: '100px';
    margin-left: '30px';
  }
  .qms {
    margin-left: 70px;
    text-align: center;
    
    
  }
  
  #hero .carousel-container{
    height: 40vh;
    }
  .elnimg {
    height: auto;
    width: 75vw;
    margin-left: 10px;
  }

  .elnkeyfeatures {
    text-align: center;
    margin-right: 20%;
  }
  .lablims{
    height: auto;
    width:90vw;
    margin-left: 10px;
  }
  .adbsimg{
    height: auto;
    width: 85vw;
    margin-left: 25px;
  }

}
.career-container {
  background-color: rgba(4,52,111) !important;
  width: auto;
  height: auto;
  color: black;
  margin-bottom: 80px;
}

.text-styling {
  font-family: Arial;
  font-size: 10px;
}

.square-image {
  width: 700vw;
  /* Set width to 100% to fill the container */
  height: auto;
  /* Maintain aspect ratio */
}


.custom-list {
  list-style-type: circle;
}

.job-container {
  background-color: #f0f5f7;
  height: auto;
  width: 90vw;
  border-radius: 20px;
}

.button {
  background-color: transparent;
  border-color: rgb(60, 192, 236);
  border-radius: 10px;
  font-size: 20px !important;
  background-color: #47b2e4;
}

.limsdashboard {
  height:25vh;
  text-align: center;
  width: 90vw;
  margin-left: 20px;
}
.limsdashboards {
  text-align: center;
  height:40vh !important;
  width: 55vw;
  margin-left: 20px;
}

.ravi {
  width: 45vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 80px;
  text-align: center;
}

#headeritems {
  color: black !important;
}


@media (min-width: 768px) {
  .alignment {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 45vh;
  }


  .limsdashboard {
    width: 30vw;
    height: 40vh;
  }
    
  .limsdashboards {
      height:65vh !important;
      text-align: center;
      width: 25vw;
      margin-left: 20px;
    }

  p {
    text-align: start;

  }
  li {
    text-align: start;
  }
  .services .description {
    text-align: start;
  }
  

  .ravi {
    width: 25vw;
    margin-left: 85px;
  }

  #headeritems {
    color: black !important;
  }
}


p {
  text-align: justify;
  hyphens: auto;

}

li {
  text-align: justify;
  hyphens: auto;
}

.services .describe {
  font-size: 14px;
  margin-left: 80px;
  line-height: 24px;
  margin-bottom: 0;
  color: rgb(13, 14, 15);
  text-align: start !important;
}
/* Add this CSS to your stylesheet */
.linkClassName {
  text-decoration: none; /* Remove underlines from links */
}


.portfolio-details {
  padding-top: 40px;
}
.portfolio-details .portfolio-details-slider img {
  overflow: hidden;
  /* max-height: 100%; */
  max-width: 100%;
}
.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}
.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #47b2e4;
}
.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #47b2e4;
}
.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(55, 81, 126, 0.08);
}
.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}
.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}
.portfolio-details .portfolio-info ul li + li {
  margin-top: 10px;
}
.portfolio-details .portfolio-description {
  text-align: justify;
  padding-top: 30px;
}
.portfolio-details .portfolio-description h2 {
  text-align: justify;
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}
.portfolio-details .portfolio-description p {
  text-align: justify;
  text-decoration: column;
  padding: 0;
}
.dropdown-menu[data-bs-popper]{
  top: 100%;
  left: -109px !important;
}

.scrolling-line {
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  background-color: #000;
  color: #fff;
  font-size: 24px;
  padding: 10px;
}

.scrolling-line::after {
  content: attr(data-text);
  animation: scrolling 10s linear infinite;
}

@keyframes scrolling {
  0% {
      transform: translateX(100%);
  }
  100% {
      transform: translateX(-100%);
  }
}

.icon-box {
  position: relative;
}

.icon-box:hover .default-text {
  display: none;
}
.hover-text {
  display: none;
}

.icon-box:hover .hover-text {
  display: inline;
}
#scroll-wrapper {
  width: 100%; /* Set width of scrollable area */
  margin: 0 auto; /* Center align the scrollable area */
  overflow: hidden; /* Hide overflow to prevent scrollbars */
  position: relative; /* Needed for absolute positioning of image container */
}
#image-container {
  width: 80%;
  height: 20%;
  margin-top: 4%;
  white-space: nowrap; /* Prevent images from wrapping */
  animation: scrollImages 50s linear infinite; /* Adjust animation duration as needed */
}
.image {
  display: inline-block;
  max-width: 100%; /* Ensure images don't exceed container width */
  height: auto; /* Maintain aspect ratio */
  vertical-align: middle;
}
@keyframes scrollImages {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.cookie-policy{
  position: fixed;
    left: 10px;
    overflow: visible;
    top: 480px;
    bottom: 0;
    margin: auto;
    width: 350px;
    box-shadow: 1px 1px 10px -1px #aaa;
    border-radius: 5px;
    /* padding: 10px 15px; */
    height: 185px;
    color: #333;
    line-height: 20px;
    background-color: #fff;
    display: flex;
    display: -ms-flexbox;
    flex-direction: column;
    /* justify-content: center; */
    min-width: 200px;
}
.Cookie-head{
  text-align: left;
  line-height: 1.25;
  font-size: 22px;
  font-weight: 400;
}
.cookieBody{
  padding: 20px 23px 8px;
}
.cookieBody p{
  font-size: 14px;
  color: rgb(83, 83, 83);
}
.cookie-buttons{
  display: flex;
  justify-content: space-around;
  background: rgba(4, 52, 111, 0.8);
  padding: 13px;
}
.cookie-buttons button{
  color: #fff;
  width: 100px;
}
.basic-nav-dropdown{
  background-color: rgba(4, 52, 111, 0.8) !important;
}